
import http from "../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import moment from "moment";
import router from "../router";
import { format } from "date-fns";

export default defineComponent({
  setup(props) {
    const form = ref({});

    const farms = ref([]);
    const farm = ref(0);
    const farmName = computed(() => {
      const n = farms.value?.find((f: any) => f.id == farm.value);

      return n?.["name"];
    });

    const varieties = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: "",
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 4
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then((res) => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks(f: any) {
      const res = await http.get(
        `/api/picks?farm_id=${f}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchPicks(id).then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then((res) => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                  },
                }),
                {}
              ),
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function searchVarieties() {
      fetchPicks(farm.value).then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}`).then((res) => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                  },
                }),
                {}
              ),
            };
          }, {});
        });
      });
    }

    function savePicks() {
      http
        .post("/api/picks", {
          farm_id: farm.value,
          picks: form.value,
          start: start.value,
          end: end.value,
        })
        .then((res) => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchVarieties(farm.value);
        });
    }

    function updateAssignment(assignment: any) {
      assignment._method = "PUT";
      http
        .post(`/api/varieties/${assignment.id}`, assignment)
        .then((res) => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchVarieties(farm.value);
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then((res) => {
        fetchVarieties(farm.value);
      });
    }

    function isFilled(p: any) {
      const data = Object.entries(form.value);

      const x = data.filter((n: any) => {
        const f: any = (n[1] as any)[p];
        return Number(f?.projected) > 0;
      });

      return x.length > 0;
    }

    function getSum(p: any, section: any) {
      const data = Object.entries(form.value);

      const x = data.filter((n: any) => {
        const f: any = (n[1] as any)[p];
        return Number(f?.projected) > 0;
      });

      const y = Object.values(x);
      console.log(y);

      return y.reduce((o: any, m: any) => {
        const n: any = (m[1] as any)[p];
        return o + parseFloat(n[section]);
      }, 0);
    }

    onMounted(() => {
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm.value = Number(f);
        fetchVarieties(f);
      } else {
        farm.value = 1;
        fetchVarieties(1);
      }
    });

    watch(
      () => start.value,
      () => {
        fetchVarieties(farm.value);
      }
    );

    function updateForm() {
      fetchVarieties(farm.value);
    }

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      savePicks,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      getDates,
      start,
      end,
      form,
      farm,
      farms,
      farmName,
      updateForm,
      isFilled,
      getSum,
    };
  },
});
